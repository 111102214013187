/* eslint-disable no-console, no-undef, camelcase */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Modal } from 'react-bootstrap';
import { Bold, StyledModal, StyledBody, SubmitContainer, UserButton, StyledTitle } from './styles';
import { withTranslation } from 'react-i18next';

class Example extends Component {

  render() {
    const { showModal, closeModal, deleteWork, WorkoutId, t } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={() => closeModal()}
        dialogClassName={'delete-popup'}
        backdropClassName='drop-back'
      >
        <Modal.Header >
          <StyledTitle>
            {t("Delete Log Workout")}
          </StyledTitle>
        </Modal.Header>
        <StyledBody>
          <Bold>
            {t("Are you sure want to delete this Workout?")}
            <div className="subContent">
            </div>
          </Bold>
          <SubmitContainer>
            <UserButton
              radius="5px"
              bgColor="#FD7175"
              width="170px"
              height="50px"
              fontSize="18px"
              Align={1}
              float={1}
              marginLeft="0px"
              textColor="white"
              capitalize="1"
              font="rubik"
              onClick={() => deleteWork(WorkoutId)}
            >
              {t("Delete")}
            </UserButton>
            <UserButton
              radius="5px"
              bgColor="none"
              width="170px"
              height="50px"
              fontSize="18px"
              Align={1}
              float={1}
              marginLeft="10px"
              capitalize="1"
              font="rubik"
              onClick={() => closeModal()}
              innerRef={this.cancelButton}
              outline={0}
            >
              {t("Cancel")}
            </UserButton>
          </SubmitContainer>
        </StyledBody>
      </StyledModal>
    )
  }
}

Example.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteWork: PropTypes.func.isRequired,
  WorkoutId: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(Example));