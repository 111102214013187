/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import momentTZ from 'moment-timezone';
import { WorkoutContainer, SubContainer, CardSection, ActivityDetails, Details, DayWorkOut, TimeContainer, WorkNew } from './styles';
import { ImageUrl } from '../../utils/constants';
import { isEmpty } from 'lodash';
import moment from "moment";
import { getMainConstraint, convertMilesToKm } from '../../utils/methods';
import Example from './Example';
import { connect } from 'react-redux';
import { deleteWork } from './../../redux/actions';
import { ButtonContainer } from './SingleActivity/styles';
import { withTranslation } from 'react-i18next';

const Days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];
const units = {
  "day": 24 * 60,
  "hour": 60,
  "minute": 1
};

class DayWiseWorkOut extends React.Component {

  constructor(props) {
    super();
    this.state = {
      Showpopup: false,
      selectedIndex: "",
      showModal: false,
      WorkoutId: "",
    }
  }


  printDay = (startDate) => {
    const currentday = startDate ? startDate : null;
    var today = new Date(currentday);
    const day = today.getDay();
    return Days[day];
  }

  printDate = (startDate) => {
    const currentday = startDate ? moment(startDate).format('MM/DD/YYYY') : null;
    return currentday;
  }

  printStatus = (startDate) => {
    const firstdate = startDate;
    const currentdate = momentTZ();
    let diff = Math.abs(getMainConstraint(momentTZ(firstdate), currentdate));
    let newResult = [];
    for (let name in units) {
      let p = Math.floor(diff / units[name]);
      if (newResult.length < 2) {
        if (p === 1) newResult.push(p + " " + name);
        if (p >= 2) newResult.push(p + " " + name + "s");
        diff %= units[name];
      }
    }
    if (newResult.length === 0 || (newResult[0].indexOf("minute") >= 0) || (newResult[0].indexOf("hour") >= 0)) {
      newResult = [];
      newResult.push("Today");
    } else if (newResult[0] === '1') {
      newResult.push("Yesterday");
    } else {
      newResult[0] = newResult[0] + " " + 'ago';
    }
    return newResult[0];
  }

  imageClick = (index) => {
    this.setState({ Showpopup: !this.state.Showpopup, selectedIndex: index });
  }

  callApi = (id) => {
    this.setState({ showModal: true, WorkoutId: id });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { dayWiseWorkoutHistory, deleteWork, t } = this.props;
    const { WorkoutId } = this.state;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      dayWiseWorkoutHistory && dayWiseWorkoutHistory.workout_details.length > 0 ?
        <WorkoutContainer>
          <div>
            {t("My Monthly Workout Activity")}
          </div>
          <SubContainer>
            {dayWiseWorkoutHistory && !isEmpty(dayWiseWorkoutHistory) && dayWiseWorkoutHistory.workout_details && dayWiseWorkoutHistory.workout_details.length > 0 ?
              <DayWorkOut>
                {dayWiseWorkoutHistory.workout_details && dayWiseWorkoutHistory.workout_details.length > 0 ?
                  dayWiseWorkoutHistory.workout_details.map((data, index) => (
                    <CardSection intensityCheck={data.intesity ? 1 : 0} key={index}>
                      <div className={'iconCard'}>
                        <img src={`${ImageUrl}/${data.interest_icon}`} />
                      </div>
                      <div>
                        <TimeContainer>
                          <div>
                            {this.printDay(data.start_date)}
                            {' '}
                            {this.printDate(data.start_date)}
                            {', '}
                            {this.printStatus(data.start_date)}
                          </div>
                          <div style={{ "padding-right": "15px" }}> {data.intesity ? <img onClick={() => this.imageClick(index)} src="public/images/workout_menu_icon.png" style={{ height: '5px', margin: '0 0', "cursor": "pointer" }} /> : ""} </div>
                        </TimeContainer>
                        <WorkNew>
                          <div >{data.activity}  </div>
                        </WorkNew>
                        <ActivityDetails margin={"1"}>
                          <Details marginRight={1}>
                            {data.duration > 0 ?
                              (<div style={{ "margin-right": "6.2px" }}>
                                <img src="/public/images/NewDashboardV2/timeImg.png" height={20} style={{ "width": "auto" }} />
                              </div>) : null}
                            {data.duration > 0 ?
                              (<div style={{ "color": "#9c9c9c" }}>
                                {data.duration} mins&nbsp;&nbsp;&nbsp;
                              </div>) : null}
                            {data.distance > 0 ?
                              (<div>
                                <img src="/public/images/NewDashboardV2/locationNew.png" height={20} style={{ "width": "auto" }}/>&nbsp;&nbsp;
                              </div>) : null}
                            {data.distance > 0 ?
                            <div style={{ "color": "#9c9c9c" }}>
                              {DistanceUnit === "KILOMETER"? `${convertMilesToKm(data.distance)} Km` : `${data.distance} miles`}&nbsp;&nbsp;&nbsp;
                            </div>:null}
                            {data.intesity ? <div className={'counterContainer'} >
                              <div className={'counterImage'}>
                                <img src="/public/images/NewDashboardV2/intensity-icon.png" height={20} style={{ "width": "auto" }}/>
                              </div>
                              {(data.intesity >= 0 && data.intesity < 2) ? <div className={'counterTitle'}>
                                {t("Rest")}
                              </div> :
                                (data.intesity >= 2 && data.intesity < 4) ? <div className={'counterTitle'}>
                                  {t("Easy")} </div> : (data.intesity >= 4 && data.intesity < 6) ? <div className={'counterTitle'}>
                                    {t("Moderate")} </div> : (data.intesity >= 6 && data.intesity < 8) ? <div className={'counterTitle'}>
                                      {t("Hard")} </div> : (data.intesity >= 8 && data.intesity < 10) ? <div className={'counterTitle'}>
                                        {t("Max")} </div> : null}
                            </div> : null}
                            {data.calories ? <div className={'counterContainer'} >
                              <div className={'counterImage'}>
                                <img src="/public/images/NewDashboardV2/calories.png" height={20} />
                              </div>
                              <div className={'counterTitle'}>
                                {data.calories} KCal
                              </div>
                            </div> : null}
                          </Details>
                          {data.intensity ?
                            <Details marginRight={1}>
                              <div>
                                <img src="/public/images/NewDashboardV2/duration.png" />
                              </div>
                              <div>
                                {data.intensity}
                              </div>
                            </Details>
                            : null
                          }
                        </ActivityDetails>
                        <div>
                          {data.intesity && this.state.Showpopup && this.state.selectedIndex === index && <ButtonContainer> <button onClick={() => this.callApi(data.id)}>{t("Delete")}</button></ButtonContainer>}
                        </div>
                      </div>
                    </CardSection>
                  ))
                  : null
                }
              </DayWorkOut>
              : null
            }
            <div>
              {this.state.showModal && <Example WorkoutId={WorkoutId} showModal={this.state.showModal} closeModal={this.closeModal} deleteWork={deleteWork} />}
            </div>
          </SubContainer>
        </WorkoutContainer>
        : null
    );
  }
}

DayWiseWorkOut.propTypes = {
  dayWiseWorkoutHistory: PropTypes.object,
  deleteWork: PropTypes.function,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  deleteWork: (id) => dispatch(deleteWork(id))
});

export default connect(null, mapDispatchToProps)(withTranslation()(DayWiseWorkOut));
