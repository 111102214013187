import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BirthdayContainer, PeopleContainer, IconContainerNew, Button, MainContainer } from './styles';
// import { ImageUrl } from '../../utils/constants';
import { findMonth } from '../../utils/methods';
import { getWorkAnniversary } from '../../redux/actions';
import { isNull, isUndefined, isEmpty } from 'lodash';
import { LoaderContainer } from "../Content/styles";
import Loading from '../Loading';
import { withTranslation } from 'react-i18next';

class WorkAnniversaryCard extends React.Component {
  // totalBirthdayCount = 0;
  anniversaryObject = {};
  componentDidMount() {
    const { getWorkAnniversary } = this.props;
    getWorkAnniversary();
  }

  componentDidUpdate(prevProps) {
    if (this.props.birthdayList !== prevProps.birthdayList) {
      this.anniversaryObject = [
        ...this.props.birthdayList.recent_anniversary,
        ...this.props.birthdayList.today_anniversary,
        ...this.props.birthdayList.upcoming_anniversary,
      ];
    }
  }

  render() {
    const { birthdayList, callBack, t } = this.props;
    const date = new Date();
    const month = findMonth(date.getMonth());
    if(isEmpty(this.anniversaryObject) || isNull(this.anniversaryObject) || isUndefined(this.anniversaryObject)){
      return(<LoaderContainer><Loading /></LoaderContainer>)
    }

    return (
      <MainContainer>
        {isEmpty(birthdayList) || isNull(birthdayList) || isUndefined(birthdayList)
          ?
          <div />
          :
          <BirthdayContainer>
            <div>
              <div>{month}</div>
              <div>{t('Work Anniversaries')}</div>
            </div>
            <div>
              <PeopleContainer>
                <IconContainerNew>
                  <span className="circle">
                    {this.anniversaryObject && this.anniversaryObject.length > 0 && this.anniversaryObject.length}+</span>
                </IconContainerNew>
                <div><span>{t(`People's Anniversaries`)}</span></div>
              </PeopleContainer>
              <Button onClick={callBack}>
                {t('Shout Out')}
              </Button>
            </div>
          </BirthdayContainer>
        }
      </MainContainer>
    );
  }
}

WorkAnniversaryCard.propTypes = {
  birthdayList: PropTypes.array,
  getWorkAnniversary: PropTypes.func,
  callBack: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  birthdayList: state.profileData.workAnniversaryList,
})

const mapDispatchToProps = (dispatch) => ({
  getWorkAnniversary: () => dispatch(getWorkAnniversary())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkAnniversaryCard));
