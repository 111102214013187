import styled from 'styled-components';
import BgImg from '/public/images/social/workAnniversary.png';
const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
  height: 134px;
  margin-bottom: 20px;
`

const BirthdayContainer = styled.div`
width: 100%;
height: 100%;
background: url(${BgImg});
background-repeat: no-repeat;
background-size: 100% 100%;
padding: 10px 10px 15px 10px;
display: flex;
justify-content: space-evenly;
flex-flow: column;
border-radius: 5px;
margin-bottom: 0px;

  > div: first-child {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    margin-bottom: 20px;

    > div: first-child {
      font-family: Rubik;
      font-size: 14px;
      text-transform: uppercase;
      color: #1E1E60;
      margin-top: 10px;
      line-height: 17px;
    }
    > div: nth-child(2) {
      font-family: Rubik-Medium;
      font-size: 24px;
      color: #FFF;
      Line height: 28px
    }
  }

  > div: last-child {
    display: flex;
    align-items: center;
		width: 100%;
		padding-left: 10px;
  }
`;
const PeopleContainer = styled.div`
width: 67%;
height: 100%;
display: flex;

  > div: last-child {
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 14px;
    font-family: 'Rubik-Regular';
    padding: 1px;
    padding-left: 3px;
    line-height: 17px;
    align-items: center;
    > span {
      align-items: center;
      // padding-top: 5px;
      padding-left: 5px;
    }
  }
`;
const IconContainer = styled.div`
display: flex;
align-items: center;

  > div: first-child {
    margin-left: 0px;
  }
  > span {
    margin-left: -12px;

    > img {
			width: 24px;
			height: 24px;
      border-radius: 50%;
    }
  }

  .circle {
    font-family: ${MediumFont};
    font-size: 9px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    background-color: #243A5A;
  }
`;
const Circle = styled.div`
font-family: ${MediumFont};
font-size: 11px;
color: #fff;
display: flex;
align-items: center;
justify-content: center;
width: 23px;
height: 23px;
border-radius: 50%;
border: 2px solid #fff;
text-align: center;
background-color: #FFF;
margin-left: -12px;
`;
const Button = styled.button`
border: 1px solid #FFF;
box-sizing: border-box;
border-radius: 3px;
color: #FFF;
background: transparent;
width: 60%;
padding: 6px;
width: 33%;
font-family: 'Rubik-Medium';
font-size: 16px;
line-height: 20px;
`;

const IconContainerNew = styled.div`
display: flex;
align-items: center;

  > div: first-child {
    margin-left: 0px;
  }
  > span {
    margin-left: -12px;

    > img {
			width: 24px;
			height: 24px;
      border-radius: 50%;
    }
  }

  .circle {
    font-family: ${MediumFont};
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 30px;
    min-width: 30px;
    padding: 8px;
    border-radius: 50%;
    text-align: center;
    background-color: #243A5A;
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border: 2px solid #FFFFFF;
border-radius: 15px;
  }
`;

export {
  BirthdayContainer, PeopleContainer, IconContainer, Circle, Button, MainContainer, IconContainerNew
};
